import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(0.000000, 0.000000)">
        <path
          d="M 43.372 31.589 C 39.065 33.713, 36.528 38.672, 37.393 43.280 C 38.326 48.257, 42.669 51.637, 49.977 53.075 C 56.885 54.434, 59.538 56.720, 58.591 60.495 C 57.287 65.688, 47.355 66.368, 43.462 61.532 C 41.388 58.954, 38.599 58.334, 36.126 59.901 C 34.985 60.624, 35.054 61.283, 36.536 63.791 C 43.676 75.879, 66.333 72.028, 66.333 58.727 C 66.333 51.072, 63.707 48.835, 50 44.819 C 46.449 43.778, 45.427 42.984, 45.155 41.056 C 44.471 36.208, 51.528 34.268, 56.273 38 C 58.975 40.126, 61.638 40.516, 63.855 39.110 C 64.975 38.401, 64.921 37.810, 63.547 35.712 C 60.069 30.404, 49.961 28.338, 43.372 31.589"
          fill="#61f6d6"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
